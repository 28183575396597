import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

// https://www.gatsbyjs.com/docs/add-seo-component/

const Head = ({ title, description, image }) => {
  const { pathname } = useLocation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl
            defaultImage: image
          }
        }
      }
    `,
  );

  const { defaultTitle, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={title} defaultTitle={seo.title} titleTemplate={`%s | ${defaultTitle}`}>
      <html lang="en" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <meta
        name="keywords"
        content="Tech Tinkerer, Coding, AI, Software Engineer, Software Developer, Web Developer, Web Engineer, Full Stack Developer, Full Stack Engineer, Front End Developer, Front End Engineer, Back End Developer, Back End Engineer, Software Architect, Software Designer, Software Consultant, Software Contractor, Software Freelancer, Software Engineer Consultant, Software Engineer Contractor, Software Engineer Freelancer, Software Developer Consultant, Software Developer Contractor, Software Developer Freelancer, Web Developer Consultant, Web Developer Contractor, Web Developer Freelancer, Full Stack Developer, Software Freelancer, Freelancer"
      />
      <meta name="google-site-verification" content="ILdVc7rAALIlOlLURSqvZBHvEmge_uas_mTw3v-NR-8" />
    </Helmet>
  );
};

export default Head;

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

Head.defaultProps = {
  title: null,
  description: null,
  image: null,
};
