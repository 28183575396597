import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ffffff05;
    z-index: -1;
    filter: blur(1px);
    left: 0;
    background: linear-gradient(
      137deg,
      rgb(14, 29, 51) 20%,
      rgba(14, 29, 51, 0.8719420531884629) 56%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h2 {
    margin: 0 0 30px 4px;
    color: var(--orange);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .home-background {
    position: absolute;
    z-index: -10;
    height: 100%;
    // width: 600px;
    filter: blur(0px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h2>Hey there, my name is</h2>;
  const two = (
    <h3 className="big-heading" style={{ 'margin-bottom': '0rem' }}>
      André Macedo{' '}
    </h3>
  );
  const three = <h1 className="big-heading text-blue">aka Tech Tinkerer.</h1>;
  const four = (
    <>
      <br></br>
      <p style={{ 'max-width': '800px' }}>
        I’m a passionate tech enthusiast, software engineer, and web developer. Curiosity driven and
        challenge oriented, that's what keeps me going.
      </p>
    </>
  );

  const items = [one, two, three, four];

  return (
    <StyledHeroSection id="hero">
      <img alt="background" className="home-background" src="/hero-background.svg"></img>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
