import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <circle
      cx="50"
      cy="50"
      r="37"
      strokeWidth="2"
      stroke="#ea8e48"
      strokeDasharray="58.119464091411174 58.119464091411174"
      fill="none"
      strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="0.22075055187637968s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;360 50 50"></animateTransform>
    </circle>
    <circle
      cx="50"
      cy="50"
      r="34"
      strokeWidth="2"
      stroke="#ccd6f6"
      strokeDasharray="53.40707511102649 53.40707511102649"
      strokeDashoffset="53.40707511102649"
      fill="none"
      strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="0.22075055187637968s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;-360 50 50"></animateTransform>
    </circle>
  </svg>
);

export default IconLoader;
