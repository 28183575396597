import React from 'react';

const IconBookmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#fff"
    stroke="#svgradient"
    strokeWidth="0.1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-bookmark">
    <defs>
      <linearGradient id="svgradient" x1="0" x2="1" y1="0" y2="1.2">
        <stop offset="5%" stopColor="#37F478" />
        <stop offset="85%" stopColor="#4DD7F7" />
      </linearGradient>
    </defs>
    <title>Prompt</title>
    <path fill="#444" d="M6 12h9v1h-9v-1z"></path>
    <path fill="#444" d="M1.1 13h1.2l3.7-5-3.7-5h-1.3l3.8 5z"></path>
  </svg>
);

export default IconBookmark;
